import { BaseFormatter } from "./";
import { APPLICATION_FORMATS } from "./";

class BoolFormatter extends BaseFormatter {
    constructor() {
        super(APPLICATION_FORMATS.BOOL);
    }

    format(isTrue, { trueLabel, falseLabel }) {
        return isTrue ? trueLabel : falseLabel;
    }
}

const boolFormatter = new BoolFormatter();
export default boolFormatter;
