import { find, cloneDeep, minBy } from "lodash-es";

class ProductStore {
    _products = [];

    get products() {
        return cloneDeep(this._products);
    }

    get cheapestProduct() {
        return minBy(this._products, product => product.price);
    }

    constructor() {
        this._products = RadioDeviceProducts.products;
    }

    getProduct(productId) {
        return find(this._products, product => product.productId === productId);
    }
}

const productStore = new ProductStore();
export default productStore;