import { localStorageHandler } from '.';
import appProvider from './appProvider';
import CheckoutService from '../services/CheckoutService';
import { observable, action, makeObservable, computed, reaction } from 'mobx';
import { find, findIndex, sumBy, map, cloneDeep } from 'lodash-es';

class Shopping_cart {
    _cart = [];
    _storageKey = 'shopping-cart';
    _checkoutKey = 'checkout';

    get items() {
        return [...this._cart];
    }

    get count() {
        return this._cart.length;
    }

    get totalQuantity() {
        return sumBy(this._cart, (item) => item.quantity);
    }

    get totalPrice() {
        return sumBy(this._cart, (item) => item.quantity * item.product.price);
    }

    constructor() {
        makeObservable(this, {
            _cart: observable,
            items: computed,
            count: computed,
            totalQuantity: computed,
            totalPrice: computed,
            add: action,
            remove: action,
            increaseQuntity: action,
            decreaseQuntity: action,
        });
        const items = localStorageHandler.get(this._storageKey);
        if (items) {
            this._cart = items;
        }
        reaction(
            () => this._cart,
            () => {
                this._updateStorage();
            }
        );
        this.checkoutService = new CheckoutService(appProvider.apiClient);
    }

    add(item) {
        const existingItem = find(this._cart, (x) => x.product.productId === item.productId);
        if (existingItem) {
            existingItem.quantity++;
        } else {
            this._cart.push({
                product: item,
                quantity: 1,
            });
        }
        this._update();
    }

    remove(productId) {
        const existingItemIndex = findIndex(this._cart, (x) => x.product.productId === productId);
        this._cart.splice(existingItemIndex, 1);
        this._update();
    }

    increaseQuntity(productId) {
        const existingItem = find(this._cart, (x) => x.product.productId === productId);
        if (existingItem) {
            existingItem.quantity++;
        }
        this._update();
    }

    decreaseQuntity(productId) {
        const existingItemIndex = findIndex(this._cart, (x) => x.product.productId === productId);
        if (this._cart[existingItemIndex].quantity === 1) {
            this._cart.splice(existingItemIndex, 1);
        } else {
            this._cart[existingItemIndex].quantity--;
        }
        this._update();
    }

    async checkout() {
        if (this.count > 0) {
            const checkoutItems = cloneDeep(this._cart);
            const lineItems = map(checkoutItems, (item) => {
                return {
                    paymentProviderProductId: item.product.productId,
                    quantity: item.quantity,
                };
            });
            const response = await this.checkoutService.checkout({
                successUrl: generateSuccessReturnUrl(),
                cancelUrl: generateCancelReturnUrl(),
                lineItems: lineItems,
            });
            localStorageHandler.set(this._checkoutKey, {
                returnUrlId: response.data.checkoutReturnUrlId,
                lineItems: checkoutItems,
            });

            let a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = response.data.paymentProviderCheckoutUrl;
            a.click();
            document.body.removeChild(a);
        }
    }

    getAndRemoveCheckoutItems(checkoutReturnUrlId) {
        const data = localStorageHandler.get(this._checkoutKey);
        if (data && data.returnUrlId === checkoutReturnUrlId) {
            localStorageHandler.remove(this._checkoutKey);
            return data.lineItems;
        }

        return [];
    }

    _update() {
        this._cart = [...this._cart];
    }

    _updateStorage() {
        localStorageHandler.set(this._storageKey, this._cart);
    }

    clear() {
        this._cart = [];
    }
}

function generateSuccessReturnUrl() {
    return `${window.location.origin}/payment/success?checkout={checkoutId}`;
}

function generateCancelReturnUrl() {
    return `${window.location.origin}/naki-radio`;
}

const shopping_cart = new Shopping_cart();
export default shopping_cart;
