class BaseFormatter {
    constructor(formatType) {
        this.formatType = formatType;
    }

    canFormat(format) {
        return this.formatType === format;
    }

    format(value, options) {
        throw new Error("Not implemented.");
    }
}

export default BaseFormatter;
