const menuNavigation = () => {
    const navigation = document.querySelector(".c-nav");
    const navLinks = document.querySelectorAll(".c-nav__link")
    const dropdowns = document.querySelectorAll(".c-nav__dropdown");
    const dropdownList = document.querySelector(".c-nav__dropdown__list");
    const dropdownLink = document.querySelectorAll(".c-nav__dropdown__link");
    const hamburgerMenu = document.querySelector(".c-header__menu");

    const cssActive = `
        visibility: visible;
        opacity: 1;
        transform: scale(1) rotateX(0) translate3d(0, 0, 0);
    `

    dropdowns.forEach(dropdown => {
        dropdown.addEventListener("mouseenter", () => {
            if (dropdown.querySelector(".c-nav__dropdown__list") !== null) {
                dropdown.querySelector(".c-nav__dropdown__list").style.cssText = cssActive
            }
        })

        dropdown.addEventListener("mouseleave", () => {
            if (window.innerWidth > 990) {
                if (dropdown.querySelector(".c-nav__dropdown__list") !== null) {
                    dropdown.querySelector(".c-nav__dropdown__list").style.cssText = `
                    visibility: hidden;
                    opacity: 0;
                    `
                }
            }
        })

        dropdown.addEventListener("click", () => {
            if (window.innerWidth > 990) {
                dropdown.querySelector(".c-nav__dropdown__list").classList.toggle("isOpen");
                dropdown.querySelector(".c-nav__dropdown__list").style.cssText = cssActive
            }
        })
    })

    hamburgerMenu.addEventListener("click", () => {
        hamburgerMenu.classList.toggle("isOpen");
        navigation.classList.toggle("isOpen")
        dropdownList.classList.remove("isOpen");
    })

    const setActive = () => {
        let pageUrl = window.location.href;

        if (pageUrl.includes("#")) {
            navLinks.forEach(link => {
                if (link.href === pageUrl.split("#")[0]) {
                    link.classList.add("isActive")
                }
            })
        }

        navLinks.forEach(link => {
            if (link.href === pageUrl) {
                link.classList.add("isActive")
            }
        })

        dropdownLink.forEach(link => {
            if (link.href === pageUrl) {
                let activeList = link.parentElement.parentElement.parentElement;
                activeList.classList.add("isActive");
                link.parentElement.classList.add("isActive");
            }
        })
    }

    setActive()
}


export default menuNavigation()