class BaseService {
    apiClient = null;
    routeService = null;    

    constructor(apiClient, routeService) {
        this.apiClient = apiClient;
        this.routeService = routeService;        
    }
}

export default BaseService;