import BaseRouteService from './BaseRouteService';

class CheckoutRouteService extends BaseRouteService {
    constructor() {
        super('payment');
    }

    checkout(resource) {
        return super.create(`${this.base}/device-checkout-session`, resource);
    }

    preprocessBundleSubscriptionPayment(planId) {
        return super.create(`${this.base}/subscription/bundle/preprocess/{planId}`, { planId });
    }

    postprocessBundleSubscriptionPayment() {
        return super.create(`${this.base}/subscription/bundle/postprocess`);
    }

    createSubscriptionPaymentPreview() {
        return super.create(`${this.base}/subscription-payment-preview`);
    }
}

export default CheckoutRouteService;
