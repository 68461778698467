import baasicAppFactory from "./baasicAppFactory";

class BaasicOptions {
    constructor(config) {
        this.useSSL = config.useSSL;
        this.apiRootUrl = config.appUrl;
        this.apiVersion = config.apiVersion;
        this.apiKey = config.apiKey;
    }
}

class AppProvider {
    app = null;    

    get apiClient() {
        return this.app.apiClient;
    }

    constructor() {
        this.register(ApplicationSettings);
    }

    register(applicationSettings) {
        const { apiKey } = applicationSettings;        
        const app = baasicAppFactory.create(apiKey, new BaasicOptions(applicationSettings));

        this.app = app;
    }
}

const appProvider = new AppProvider();
export default appProvider;