import { BaseFormatter } from "./";
import { APPLICATION_FORMATS } from "./";
import { isNil } from "lodash-es";

class NumberFormatter extends BaseFormatter {
    _formatters = {};

    constructor() {
        super(APPLICATION_FORMATS.NUMBER);
    }

    format(value, { locale = "en-US" }) {
        const formatter = this._getOrAddFormatter(locale);

        return formatter.format(value);
    }

    formatCurrency(value) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        })

        return formatter.format(value);
    }

    _getOrAddFormatter(locale) {
        const key = locale;
        let formatter = this._formatters[key];
        if (isNil(formatter)) {
            formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: 2 });;
            this._formatters[key] = formatter;
        }

        return formatter;
    }
}

const numberFormatter = new NumberFormatter();
export default numberFormatter;
