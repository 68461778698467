import { APPLICATION_FORMATS, applicationFormatter } from './utils';
import { localStorageHandler, productStore } from './core';

import { menuNavigation } from './navigation/menuNavigation';

import AOS from 'aos';

AOS.init();

document.addEventListener('DOMContentLoaded', () => {
    const premiumModal = document.querySelector('#premium-modal');
    const premiumModalClose = document.querySelector('#premium-modal-close');
    const premiumModalAction = document.querySelector('#premium-modal-action');

    const shouldHideModal = localStorageHandler.get('free-naki-radio-promo');
    const shouldHideFreeNakiRadioAlert = localStorageHandler.get('show-free-naki-radio-promo');

    const freeNakiRadioAlert = document.querySelector("#free-naki-radio-alert")
    const freeNakiRadioClose = document.querySelector("#free-naki-radio-promo-close")

    const hideModal = () => {
        localStorageHandler.set('free-naki-radio-promo', true);

        if (premiumModal) {
            premiumModal.classList.add('hidden');
        }
    };

    if (shouldHideModal && premiumModal) {
        premiumModal.classList.add('hidden');
    }

    if (premiumModalClose) {
        premiumModalClose.addEventListener('click', hideModal);
    }

    if (premiumModalAction) {
        premiumModalAction.addEventListener('click', hideModal);
    }
    
    const hideFreeNakiRadio = () => {
        localStorageHandler.set('show-free-naki-radio-promo', true);

        if (freeNakiRadioAlert) {
            freeNakiRadioAlert.classList.add('hidden');
        }
    }

    if (shouldHideFreeNakiRadioAlert) {
        freeNakiRadioAlert.classList.add('hidden');
    }

    if (freeNakiRadioAlert) {
        freeNakiRadioClose.addEventListener('click', hideFreeNakiRadio);
    }
});

const menuToggle = document.querySelector('#menu__toggle');
const navigation = document.querySelector('.c-header__navigation');
const navigationLink = document.querySelectorAll('.c-header__navigation__link');
const navigationWrapper = document.querySelector('.c-header__navigation__wrapper');

$('.jq-portal-url').attr('href', ApplicationSettings.portalPublicAddress);
$('.jq-min-product-price').text(
    applicationFormatter.format(APPLICATION_FORMATS.CURRENCY, productStore.cheapestProduct.price, { currency: 'USD' })
);

navigationLink.forEach((link) => {
    link.addEventListener('click', () => {
        navigation.classList.remove('isOpen');
        navigationWrapper.classList.remove('isOpen');
        menuToggle.checked = false;
    });
});

const showMore = document.querySelector('#show-more');

if (showMore) {
    showMore.addEventListener('click', () => {
        document.querySelectorAll('.hide').forEach((el) => {
            el.classList.remove('hide');
            showMore.style.display = 'none';
        });
    });
}
