import { isObject, join, extend, each, map, zipWith, pickBy, isNil, isEmpty } from "lodash-es";

function FindParams(options) {
    if (isObject(options)) {
        extend(
            this,
            pickBy(options, (value) => !isNil(value) && ((isObject(value) && !isEmpty(value)) || !isObject(value)))
        );
        if (
            (options.hasOwnProperty("orderBy") && options.hasOwnProperty("orderDirection")) ||
            options.hasOwnProperty("sort")
        ) {
            if (options.hasOwnProperty("sort")) {
                this.sort = options.sort;
            } else if (options.orderBy && options.orderDirection) {
                const orderByParts = map(options.orderBy.split(","), (i) => i.trim());
                const orderDirectionParts = map(options.orderDirection.split(","), (i) => i.trim());
                if (orderByParts.length !== orderDirectionParts.length) {
                    throw new Error("Invalid sorting parameters.");
                }
                if (orderByParts.length > 0 && orderDirectionParts.length > 0) {
                    this.sort = join(
                        zipWith(
                            orderByParts,
                            orderDirectionParts,
                            (orderBy, orderDirection) => `${orderBy}|${orderDirection}`
                        ),
                        ","
                    );
                }
            }
        }
        if (options.search) {
            this.searchQuery = options.search;
        }
        if (options.hasOwnProperty("pageNumber")) {
            this.page = options.pageNumber;
        }
        if (options.hasOwnProperty("pageSize")) {
            this.rpp = options.pageSize;
        }
    } else {
        this.searchQuery = options;
    }
}

function KeyParams(id, options, propName) {
    if (isObject(id)) {
        extend(this, id);
    } else {
        if (propName !== undefined) {
            this[propName] = id;
        } else {
            this["id"] = id;
        }
    }

    if (options !== undefined && isObject(options)) {
        extend(this, options);
    }
}

function findParams(options) {
    return new FindParams(options);
}

function getParams(id, options, propName) {
    return new KeyParams(id, options, propName);
}

function batchDeleteParams(data, idPropertyName = "id") {
    let items = [];
    each(data, (item) => {
        if (isObject(item)) {
            items.push(item[idPropertyName]);
        }
    });

    return items;
}

export { findParams, getParams, batchDeleteParams };
