import { boolFormatter, currencyFormatter } from "./";
import { find, findIndex, forEach } from "lodash-es";

class ApplicationFormatter {
    _formatters = [boolFormatter, currencyFormatter];

    constructor(formatters = []) {
        forEach(formatters, (formatter) => {
            const existingFormatterIndex = findIndex(
                this._formatters,
                (existingFormatter) => existingFormatter.formatType === formatter.formatType
            );
            if (existingFormatterIndex === -1) {
                this._formatters.push(formatter);
            } else {
                this._formatters[existingFormatterIndex] = formatter;
            }
        });
    }

    format(type, value, options = {}) {
        const formatter = find(this._formatters, (formatter) => formatter.canFormat(type));
        return formatter.format(value, options);
    }
}

const applicationFormatter = new ApplicationFormatter();
export default applicationFormatter;
