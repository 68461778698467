import { appProvider } from '../core';
import BaseService from './BaseService';
import CheckoutRouteService from './CheckoutRouteService';

class CheckoutService extends BaseService {
    constructor() {
        super(appProvider.apiClient, new CheckoutRouteService());
    }

    checkout(resource) {
        const url = this.routeService.checkout(resource);
        return this.apiClient.post(url, resource);
    }

    preprocessBundleSubscriptionPayment(planId, data, abortSignal = null) {
        const url = this.routeService.preprocessBundleSubscriptionPayment(planId);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    postprocessBundleSubscriptionPayment(data, abortSignal = null) {
        const url = this.routeService.postprocessBundleSubscriptionPayment();
        return this.apiClient.post(url, data, null, abortSignal);
    }    

    createSubscriptionPaymentPreview(data, abortSignal = null) {
        const url = this.routeService.createSubscriptionPaymentPreview();
        return this.apiClient.post(url, data, null, abortSignal);
    }
}

export default CheckoutService;
